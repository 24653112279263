// RecipeList.js
import React, { useEffect, useState } from "react";
import { fetchRecipes } from "../API/api";
import { Link, useLocation } from "react-router-dom";
import "../CSS/site.css";

const RecipeList = ({ searchTerm }) => {
  const [recipes, setRecipes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  // Extract author from the query parameters
  const queryParams = new URLSearchParams(location.search);
  const author = queryParams.get("author");

  const getRecipes = async () => {
    setIsLoading(true);

    // Use author as a backend filter if present, else use search term or limit
    const filters = author
      ? { author }
      : { search: searchTerm || "", limit: 10 };
    const data = await fetchRecipes(filters);
    setRecipes(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getRecipes();
  }, [searchTerm, author]);

  // Apply additional client-side filtering if searchTerm is different from the author
  const filteredRecipes = recipes.filter((recipe) => {
    if (!searchTerm || author) return true;

    const searchTerms = searchTerm.toLowerCase().split(" ").filter(Boolean);
    return searchTerms.every((term) => {
      const matchesTitle = recipe.title
        ? recipe.title.toLowerCase().includes(term)
        : false;

      const matchesAuthor = recipe.author
        ? recipe.author.toLowerCase().includes(term)
        : false;

      const matchesCategory = recipe.category
        ? recipe.category.toLowerCase().includes(term)
        : false;

      const matchesIngredients = recipe.ingredients
        ? recipe.ingredients.some((ingredient) =>
            ingredient.toLowerCase().includes(term)
          )
        : false;

      const matchesTags = recipe.tags
        ? recipe.tags.some((tag) => tag.toLowerCase().includes(term))
        : false;

      // Check if the term matches any of the fields
      return (
        matchesTitle ||
        matchesAuthor ||
        matchesCategory ||
        matchesIngredients ||
        matchesTags
      );
    });
  });

  return (
    <div className="page">
      <div className="recipe-grid">
        {isLoading ? (
          <p>Recepten laden...</p>
        ) : filteredRecipes.length > 0 ? (
          filteredRecipes.map((recipe) => (
            <div className="recipe-card" key={recipe._id}>
              <Link to={`/recipe/${recipe._id}`}>
                <div className="recipe-card-image">
                  {recipe.photo ? (
                    <img
                      src={recipe.photo}
                      alt={recipe.title}
                      className="recipe-image"
                    />
                  ) : (
                    <div className="placeholder-image">
                      <p>Geen afbeelding</p>
                    </div>
                  )}
                </div>
                <div className="divider-line"></div>
                <div className="recipe-card-content">
                  <div className="recipecard-title">{recipe.title}</div>
                  <button className="open-recipe-button">Bekijken</button>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <p>Geen recepten gevonden.</p>
        )}
      </div>
    </div>
  );
};

export default RecipeList;
