// NotFound.js
import React from "react";
import { Link } from "react-router-dom";
import "../CSS/site.css";

const NotFound = () => {
  return (
    <div className="page">
      <div className="not-found-container">
        <h1>404 - Pagina Niet Gevonden</h1>
        <p>
          De pagina die je probeert te bereiken bestaat niet of is verplaatst.
        </p>
        <Link to="/" className="back-home-button">
          Ga Terug naar Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
