import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../CSS/site.css";

const ConfirmAccount = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { token } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("Bezig met bevestigen...");
  const [countdown, setCountdown] = useState(5); // Initial countdown value

  useEffect(() => {
    const confirmAccount = async () => {
      try {
        const response = await axios.get(`${API_URL}/auth/confirm/${token}`);
        setStatus(
          response.data.message ||
            "Account succesvol bevestigd. U kunt nu inloggen."
        );

        // Start countdown if confirmation is successful
        let timer = setInterval(() => {
          setCountdown((prevCount) => {
            if (prevCount <= 1) {
              clearInterval(timer);
              navigate("/login"); // Redirect to the login page
            }
            return prevCount - 1;
          });
        }, 1000);
      } catch (error) {
        setStatus(
          error.response?.data?.message ||
            "Er is een probleem opgetreden bij het bevestigen van uw account."
        );
      }
    };

    confirmAccount();
  }, [token, navigate]);

  return (
    <div className="page">
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h2>{status}</h2>
        {status.includes("succesvol bevestigd") && (
          <p>
            U wordt doorgestuurd naar de inlogpagina in {countdown} seconden...
          </p>
        )}
      </div>
    </div>
  );
};

export default ConfirmAccount;
