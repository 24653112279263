import React, { useEffect, useState } from "react";
import { fetchRecipeById, uploadRecipe } from "../API/api";
import { useParams, useNavigate } from "react-router-dom";
import { AddIcon, DeleteIcon } from "./Icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import "../CSS/site.css";

const RecipeEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [newRecipe, setNewRecipe] = useState({
    title: "",
    ingredients: [],
    instructions: "",
    author: "",
    category: "Geen",
    tags: [],
    photo: null,
  });

  const [ingredient, setIngredient] = useState("");
  const [newTag, setNewTag] = useState("");
  const [instructions, setInstructions] = useState("");

  useEffect(() => {
    const fetchRecipeData = async (id) => {
      try {
        const data = await fetchRecipeById(id);
        if (data) {
          setNewRecipe({
            title: data.title || "",
            ingredients: data.ingredients || [],
            instructions: data.instructions || "", // Update de state voor consistentie
            author: data.author || "",
            category: data.category || "Geen",
            tags: data.tags || [],
            photo: data.photo || null,
          });
          setInstructions(data.instructions || ""); // Zet de instructies apart
        }
      } catch (error) {
        console.error("Error fetching recipe:", error);
      }
    };

    fetchRecipeData(id);
  }, [id]);

  // Log changes in newRecipe
  // useEffect(() => {
  //   console.log("Updated recipe data:", newRecipe);
  // }, [newRecipe]);

  const handleIngredientChange = (e, index) => {
    const updatedIngredients = [...newRecipe.ingredients];
    updatedIngredients[index] = e.target.value;
    setNewRecipe({ ...newRecipe, ingredients: updatedIngredients });
  };

  const handleAddIngredient = () => {
    if (ingredient.trim()) {
      setNewRecipe((prevRecipe) => ({
        ...prevRecipe,
        ingredients: [...prevRecipe.ingredients, ingredient],
      }));
      setIngredient("");
    }
  };

  const handleDeleteIngredient = (index) => {
    const updatedIngredients = newRecipe.ingredients.filter(
      (_, i) => i !== index
    );
    setNewRecipe({ ...newRecipe, ingredients: updatedIngredients });
  };

  const handleAddNewTag = () => {
    if (newTag.trim() && !newRecipe.tags.includes(newTag)) {
      setNewRecipe((prevRecipe) => ({
        ...prevRecipe,
        tags: [...prevRecipe.tags, newTag],
      }));
      setNewTag("");
    }
  };

  const handleRemoveTag = (index) => {
    const updatedTags = newRecipe.tags.filter((_, i) => i !== index);
    setNewRecipe({ ...newRecipe, tags: updatedTags });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewRecipe({ ...newRecipe, photo: file });
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    // Voeg de instructies toe aan de nieuwe recept-state
    const updatedRecipe = { ...newRecipe, instructions };

    const formData = new FormData();
    formData.append("title", updatedRecipe.title);
    formData.append("author", updatedRecipe.author);
    formData.append("instructions", updatedRecipe.instructions);
    formData.append("category", updatedRecipe.category);
    formData.append("_id", id);
    updatedRecipe.tags.forEach((tag, index) =>
      formData.append(`tags[${index}]`, tag)
    );
    updatedRecipe.ingredients.forEach((ingredient, index) =>
      formData.append(`ingredients[${index}]`, ingredient)
    );
    if (updatedRecipe.photo) {
      formData.append("photo", updatedRecipe.photo);
    }

    if (updatedRecipe.published !== undefined) {
      formData.append("published", updatedRecipe.published);
    }

    try {
      await uploadRecipe(formData);
      Swal.fire({
        icon: "success",
        title: "Recept bijgewerkt!",
        showConfirmButton: false,
        timer: 2500,
      }).then(() => {
        navigate(`/recipe/${id}`);
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Bijwerken mislukt",
        text: "Er is iets misgegaan, probeer het opnieuw.",
      });
    }
  };

  return (
    <div className="page">
      <div className="recipe-detail-container">
        <form className="recipe-form" encType="multipart/form-data">
          <div className="recipe-title">
            <label className="recipe-label">Recept: </label>
            <input
              className="recipecard-name"
              type="text"
              value={newRecipe.title}
              onChange={(e) =>
                setNewRecipe({ ...newRecipe, title: e.target.value })
              }
              placeholder="Voer de receptnaam in"
            />
          </div>

          <div className="recipe-content-layout">
            <div className="recipe-left-column">
              <div className="recipe-tags">
                <div className="edit-container-name">Tags:</div>
                <div className="ingredient-input-row">
                  <input
                    type="text"
                    placeholder="Voeg tags toe"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    className="tag-input"
                  />
                  <button
                    type="button"
                    onClick={handleAddNewTag}
                    className="no-button"
                  >
                    <AddIcon />
                  </button>
                </div>
                <div className="tags-container">
                  {newRecipe.tags.map((tag, index) => (
                    <div className="tag-pill" key={index}>
                      {tag}
                      <button
                        type="button"
                        className="remove-tag-button"
                        onClick={() => handleRemoveTag(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="recipe-ingredients">
                <div className="edit-container-name">Ingrediënten:</div>
                <div className="tag-input-container">
                  <div className="ingredient-input-row">
                    <input
                      className="tag-input"
                      type="text"
                      value={ingredient}
                      onChange={(e) => setIngredient(e.target.value)}
                    />
                    <button
                      type="button"
                      className="no-button"
                      onClick={handleAddIngredient}
                    >
                      <AddIcon />
                    </button>
                  </div>
                  <ul className="ingredient-list">
                    {newRecipe.ingredients.map((ing, index) => (
                      <li key={index} className="ingredient-item">
                        <div className="ingredient-input-row">
                          <input
                            type="text"
                            value={ing}
                            onChange={(e) => handleIngredientChange(e, index)}
                            className="tag-input"
                          />
                          <button
                            type="button"
                            className="no-button"
                            onClick={() => handleDeleteIngredient(index)}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="file-upload-container">
                <input
                  type="file"
                  id="photo-upload"
                  name="photo"
                  style={{ display: "none" }}
                  onChange={handlePhotoChange}
                />
                <label htmlFor="photo-upload" className="file-upload-button">
                  Foto Toevoegen
                </label>
                {newRecipe.photo && (
                  <span className="file-name">
                    {typeof newRecipe.photo === "string"
                      ? "Bestaande foto"
                      : newRecipe.photo.name}
                  </span>
                )}
              </div>
            </div>

            <div className="recipe-right-column">
              <div className="recipe-instructions">
                <div className="edit-container-name">Instructies:</div>
                <ReactQuill
                  value={instructions}
                  onChange={(value) => setInstructions(value)} // Update alleen de lokale state
                  placeholder="Voer hier de instructies in..."
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                    ],
                  }}
                />
              </div>
              <button onClick={handleUpload}>Recept Bijwerken</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RecipeEdit;
