// Home.js
import React, { useEffect, useState, useContext } from "react";
import { getRecentRecipes } from "../API/api";
import { AuthContext } from "../AuthContext";
import { Link } from "react-router-dom";
import "../CSS/site.css";

const Home = () => {
  const [recentRecipes, setRecentRecipes] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchRecentRecipes = async () => {
      const recipes = await getRecentRecipes();
      setRecentRecipes(recipes.slice(0, 5));
    };

    fetchRecentRecipes();
  }, []);

  return (
    <div className="page">
      <div className="intro-text">
        <h2 className="cursief">Welkom bij kookcollectief.nl</h2>
        <p>
          {user.username
            ? `Je bent ingelogd als ${user.username}`
            : "Je bent niet ingelogd"}
        </p>
        <p className="intro-text">
          Ontdek en deel je favoriete recepten! Dit platform is er voor
          kookliefhebbers om inspiratie te vinden en te delen.
        </p>
        <div className="recent-recipes">
          {/* <h3>Meest recente recepten</h3> */}
          <div className="recipe-grid">
            {recentRecipes.length > 0 ? (
              recentRecipes.map((recipe) => (
                <div className="recipe-card" key={recipe._id}>
                  <Link to={`/recipe/${recipe._id}`}>
                    <div className="recipe-card-image">
                      {recipe.photo ? (
                        <img
                          src={recipe.photo}
                          alt={recipe.title}
                          className="recipe-image"
                        />
                      ) : (
                        <div className="placeholder-image">
                          <p>Geen afbeelding</p>
                        </div>
                      )}
                    </div>
                    <div className="divider-line"></div>
                    <div className="recipe-card-content">
                      <div className="recipecard-title">{recipe.title}</div>
                      <button className="open-recipe-button">
                        Bekijken
                      </button>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p>Geen recepten gevonden.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
