import React, { useEffect, useState } from "react";
import { uploadRecipe } from "../API/api";
import { useNavigate } from "react-router-dom";
import { AddIcon, DeleteIcon } from "./Icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
import "../CSS/site.css";

const RecipeUpload = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [newRecipe, setNewRecipe] = useState({
    title: "",
    ingredients: [],
    instructions: "",
    author: username,
    category: "Geen",
    tags: [],
    photo: null,
  });
  const [ingredient, setIngredient] = useState("");
  const [newTag, setNewTag] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const storedUsername = localStorage.getItem("username");
    if (!token) {
      navigate("/login");
    } else {
      setUsername(storedUsername || "");
    }
  }, [navigate]);

  useEffect(() => {
    setNewRecipe((prevRecipe) => ({
      ...prevRecipe,
      author: username,
    }));
  }, [username]);

  const handleAddIngredient = () => {
    if (ingredient.trim()) {
      setNewRecipe((prevRecipe) => ({
        ...prevRecipe,
        ingredients: [...prevRecipe.ingredients, ingredient],
      }));
      setIngredient("");
    }
  };

  const handleDeleteIngredient = (index) => {
    setNewRecipe((prevRecipe) => {
      const updatedIngredients = [...prevRecipe.ingredients];
      updatedIngredients.splice(index, 1);
      return { ...prevRecipe, ingredients: updatedIngredients };
    });
  };

  const handleAddNewTag = () => {
    if (newTag.trim() && !newRecipe.tags.includes(newTag)) {
      setNewRecipe((prevRecipe) => ({
        ...prevRecipe,
        tags: [...prevRecipe.tags, newTag],
      }));
      setNewTag("");
    }
  };

  const handleRemoveTag = (index) => {
    setNewRecipe((prevRecipe) => {
      const updatedTags = [...prevRecipe.tags];
      updatedTags.splice(index, 1);
      return { ...prevRecipe, tags: updatedTags };
    });
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!newRecipe.title.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Titel vereist",
        text: "Voer een titel in voor het recept.",
      });
      return;
    }

    const formData = new FormData();
    formData.append("title", newRecipe.title);
    formData.append("author", newRecipe.author);
    formData.append("instructions", newRecipe.instructions);
    formData.append("category", newRecipe.category);

    newRecipe.tags.forEach((tag, index) =>
      formData.append(`tags[${index}]`, tag)
    );

    newRecipe.ingredients.forEach((ingredient, index) =>
      formData.append(`ingredients[${index}]`, ingredient)
    );

    if (newRecipe.photo) {
      formData.append("photo", newRecipe.photo);
    }

    // Verwerk Swal voor een duidelijke gebruikerservaring
    Swal.fire({
      title: "Bezig met uploaden...",
      text: "Even geduld aub terwijl we je recept uploaden",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      await uploadRecipe(formData);
      Swal.fire({
        icon: "success",
        title: "Je recept is geüpload!",
        showConfirmButton: false,
        timer: 2500,
      }).then(() => {
        navigate("/");
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Upload mislukt",
        text: "Er is iets misgegaan, probeer het opnieuw.",
      });
    }
  };

  return (
    <div className="page">
      <div className="recipe-detail-container">
        <form className="recipe-form" encType="multipart/form-data">
          <div className="recipe-title">
            <label className="recipe-label">Recept: </label>
            <input
              className="recipecard-name"
              type="text"
              value={newRecipe.title || ""}
              onChange={(e) =>
                setNewRecipe({ ...newRecipe, title: e.target.value })
              }
              autoFocus
              placeholder="Voer de naam van het recept in"
            />
          </div>

          <div className="recipe-content-layout">
            <div className="recipe-left-column">
              <div className="recipe-tags">
                <div>Tags:</div>
                <div className="tag-input-container">
                  <input
                    type="text"
                    placeholder="Voeg tags toe"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    className="tag-input"
                  />
                  <button
                    type="button"
                    onClick={handleAddNewTag}
                    className="add-tag-button"
                  >
                    +
                  </button>
                </div>
                <div className="tags-container">
                  {newRecipe.tags.map((tag, index) => (
                    <div className="tag-pill" key={index}>
                      {tag}
                      <button
                        type="button"
                        className="remove-tag-button"
                        onClick={() => handleRemoveTag(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="recipe-ingredients">
                <div>Ingrediënten:</div>
                <div className="tag-input-container">
                  <input
                    className="tag-input"
                    type="text"
                    value={ingredient}
                    onChange={(e) => setIngredient(e.target.value)}
                  />
                  <button
                    type="button"
                    className="add-tag-button"
                    onClick={handleAddIngredient}
                  >
                    +
                  </button>
                </div>
                <ul className="ingredient-list">
                  {newRecipe.ingredients.map((ing, index) => (
                    <li key={index} className="ingredient-item">
                      {ing}
                      <button
                        type="button"
                        className="add-tag-button"
                        onClick={() => handleDeleteIngredient(index)}
                      >
                        -
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="recipe-photo-container">
                <div className="recipe-photo">
                  <div>Foto:</div>
                  <div className="tag-input-container">
                    <input
                      type="file"
                      id="photo-upload"
                      name="photo"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setNewRecipe({ ...newRecipe, photo: file });
                        }
                      }}
                    />
                    <input
                      type="text"
                      className="photo-name-input"
                      value={newRecipe.photo ? newRecipe.photo.name : ""}
                      readOnly
                      placeholder="Geen foto geselecteerd"
                    />
                    {!newRecipe.photo ? (
                      <label htmlFor="photo-upload" className="add-tag-button">
                        +
                      </label>
                    ) : (
                      <button
                        type="button"
                        className="add-tag-button"
                        onClick={() =>
                          setNewRecipe({ ...newRecipe, photo: null })
                        }
                      >
                        x
                      </button>
                    )}
                  </div>

                  {newRecipe.photo && (
                    <div className="photo-preview-container">
                      <img
                        src={URL.createObjectURL(newRecipe.photo)}
                        alt="Preview"
                        className="photo-preview-image"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="recipe-right-column">
              <div className="recipe-instructions">
                <div>Instructies:</div>
                <ReactQuill
                  value={newRecipe.instructions}
                  onChange={(value) =>
                    setNewRecipe({ ...newRecipe, instructions: value })
                  }
                  placeholder="Voer hier de instructies in..."
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      // ["link"],
                      // ["clean"],
                    ],
                  }}
                />
              </div>
              <div>
                <button onClick={handleUpload}>Upload Recept</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RecipeUpload;
