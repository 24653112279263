import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Menu from "./Menu";
import Recipes from "./PAGES/RecipeList";
import RecipeUpload from "./PAGES/RecipeUpload";
import RecipeDetails from "./PAGES/RecipeDetails";
import RecipeEdit from "./PAGES/RecipeEdit";
import Home from "./PAGES/Start";
import ConfirmAccount from "./PAGES/ConfirmAccount";
import NotFound from "./PAGES/NotFound";
import "./CSS/site.css";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token);
  }, []);

  return (
    <Router>
      <div className="App">
        <Menu
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
          onSearchChange={setSearchTerm}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth/confirm/:token" element={<ConfirmAccount />} />
          <Route
            path="/recipes"
            element={<Recipes searchTerm={searchTerm} />}
          />
          <Route path="/upload" element={<RecipeUpload />} />
          <Route path="/recipe/:id" element={<RecipeDetails />} />
          <Route path="/edit/:id" element={<RecipeEdit />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      {/* <Footer /> */}
    </Router>
  );
};

export default App;
