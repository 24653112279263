// Menu.js
import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import logo from "../src/IMAGES/pan.png";
import { AuthContext } from "./AuthContext";
import { loginUser, registerUser, checkAvailability } from "./API/api";
import "../src/CSS/menu.css";

const MySwal = withReactContent(Swal);

const Menu = ({ onSearchChange }) => {
  const { user, logout, login } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = () => {
    onSearchChange(searchTerm);
    setSearchTerm("");
    if (location.pathname !== "/recipes") {
      navigate("/recipes");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const goToRecipes = () => {
    setSearchTerm(""); // Reset de lokale zoekterm
    onSearchChange(""); // Reset de zoekterm in de App-component
    navigate("/recipes"); // Navigeer naar de receptenpagina
  };

  const goToUpload = () => navigate("/upload");

  // Function to open the SweetAlert2 login form
  const openLoginPopup = () => {
    MySwal.fire({
      title: "Inloggen",
      html: `
      <form id="login-form" autocomplete="off">
        <input type="email" id="email" class="swal2-input" placeholder="Emailadres">
        <input type="password" id="password" class="swal2-input" placeholder="Wachtwoord">
      </form>
    `,
      focusConfirm: false,
      confirmButtonText: "Oke",
      confirmButtonColor: "#c43402",
      scrollbarPadding: false,
      customClass: {
        title: "custom-swal-title",
        htmlContainer: "custom-swal-html",
        confirmButton: "custom-swal-button",
      },
      didOpen: () => {
        // Add "Enter" key listener to the form
        const form = document.getElementById("login-form");
        form.addEventListener("keypress", (e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            Swal.clickConfirm(); // Simulate clicking the confirm button
          }
        });
      },
      preConfirm: async () => {
        const email = document.getElementById("email").value;
        const password = document.getElementById("password").value;

        if (!email || !password) {
          Swal.showValidationMessage("Please enter both email and password");
          return;
        }

        try {
          const { token, username } = await loginUser({ email, password });
          login(token, username);

          const params = new URLSearchParams(location.search);
          const redirectUrl = params.get("redirect") || "/";
          navigate(redirectUrl);

          Swal.fire({
            icon: "success",
            html: `<div>Je bent ingelogd!</div>`,
            focusConfirm: true,
            confirmButtonText: "Oke",
            confirmButtonColor: "#c43402",
            customClass: {
              title: "custom-swal-title",
              htmlContainer: "custom-swal-html",
              confirmButton: "custom-swal-button",
            },
          });
        } catch (error) {
          Swal.showValidationMessage(
            "Inloggen mislukt. Controleer uw gegevens."
          );
        }
      },
    });
  };

  // Function to open the SweetAlert2 registration form
  const openRegisterPopup = () => {
    MySwal.fire({
      title: "Registreren",
      html: `
      <input type="text" id="username" class="swal2-input" autocomplete="off" placeholder="Gebruikersnaam">
      <input type="email" id="email" class="swal2-input" autocomplete="off" placeholder="Emailadres">
      <input type="password" id="password" autocomplete="off" class="swal2-input" placeholder="Wachtwoord">
    `,
      focusConfirm: false,
      confirmButtonText: "Registreren",
      confirmButtonColor: "#c43402",
      cancelButtonText: "Annuleren",
      cancelButtonColor: "#c43402",
      showCancelButton: true,
      customClass: {
        title: "custom-swal-title",
        htmlContainer: "custom-swal-html",
        confirmButton: "custom-swal-button",
      },

      didOpen: () => {
        document
          .getElementById("username")
          .addEventListener("input", async (e) => {
            const username = e.target.value;
            if (username.trim() === "") {
              e.target.style.backgroundColor = ""; // Reset achtergrondkleur als het veld leeg is
              return;
            }
            const result = await checkAvailability({ username });
            e.target.style.backgroundColor = result.available
              ? "#8bffae"
              : "#ffa786"; // Groen als beschikbaar, rood als bezet
          });

        document
          .getElementById("email")
          .addEventListener("input", async (e) => {
            const email = e.target.value;
            if (email.trim() === "") {
              e.target.style.backgroundColor = ""; // Reset achtergrondkleur als het veld leeg is
              return;
            }
            const result = await checkAvailability({ email });
            e.target.style.backgroundColor = result.available
              ? "#8bffae"
              : "#ffa786"; // Groen als beschikbaar, rood als bezet
          });
      },

      preConfirm: async () => {
        const username = document.getElementById("username").value;
        const email = document.getElementById("email").value;
        const password = document.getElementById("password").value;

        if (!username || !email || !password) {
          Swal.showValidationMessage("Vul alle velden in.");
          return;
        }

        try {
          await registerUser({ username, email, password });
          Swal.fire(
            "Registratie Succesvol!",
            "Controleer uw e-mail om te bevestigen.",
            "success"
          );
        } catch (error) {
          Swal.showValidationMessage("Registratie mislukt. Probeer opnieuw.");
        }
      },
    });
  };

  return (
    <div className="menu">
      <div className="logo-menu">
        <img src={logo} alt="Logo" />
        <span className="site-name" onClick={() => navigate("/")}>
          kookcollectief.nl
        </span>
      </div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Zoek naar recepten..."
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          className="search-input-menu"
        />
        <button
          onClick={handleSearchSubmit}
          className="search-button"
          aria-label="Zoeken"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </button>
      </div>
      <div className="menu-buttons-container">
        <button onClick={goToRecipes}>Recepten</button>
        {user.username ? (
          <>
            <button onClick={goToUpload}>Toevoegen</button>
            <button onClick={logout}>Afmelden</button>
          </>
        ) : (
          <>
            <button onClick={openRegisterPopup}>Registreren</button>
            <button onClick={openLoginPopup}>Inloggen</button>
          </>
        )}
      </div>
    </div>
  );
};

export default Menu;
