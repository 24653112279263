import React, { useEffect, useState, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { fetchRecipeById, deleteRecipe } from "../API/api";
import { AuthContext } from "../AuthContext";
import "../CSS/site.css";

const RecipeDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const { user } = useContext(AuthContext);
  const [isCookModeOn, setIsCookModeOn] = useState(false);

  useEffect(() => {
    const getRecipe = async () => {
      const data = await fetchRecipeById(id);
      if (!data) {
        setNotFound(true); // Stel in op true als het recept niet gevonden wordt
      } else {
        setRecipe(data);
      }
    };
    getRecipe();
  }, [id]);

  useEffect(() => {
    let wakeLock = null;

    const enableCookMode = async () => {
      try {
        wakeLock = await navigator.wakeLock.request("screen");
        console.log("Kookstand is actief!");
      } catch (err) {
        console.error("Kan kookstand niet activeren:", err);
      }
    };

    const disableCookMode = () => {
      if (wakeLock) {
        wakeLock.release();
        wakeLock = null;
        console.log("Kookstand is gedeactiveerd.");
      }
    };

    if (isCookModeOn) {
      enableCookMode();
    } else {
      disableCookMode();
    }

    return () => {
      if (wakeLock) {
        disableCookMode();
      }
    };
  }, [isCookModeOn]);

  const handleDelete = async () => {
    Swal.fire({
      title: "Weet je het zeker?",
      text: "Dit recept zal permanent worden verwijderd.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c43402",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ja, verwijderen",
      cancelButtonText: "Annuleren",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteRecipe(id); // Verwijder het recept via de API
          Swal.fire("Verwijderd!", "Het recept is verwijderd.", "success");
          navigate("/"); // Navigeer terug naar de homepage
        } catch (error) {
          Swal.fire(
            "Mislukt",
            "Er is een fout opgetreden bij het verwijderen van het recept.",
            "error"
          );
        }
      }
    });
  };

  const handleAuthorClick = () => {
    navigate(`/recipes?author=${recipe.author}`);
  };

  if (notFound) {
    return (
      <div className="page">
        <div className="not-found-container">
          <h1>Recept Niet Gevonden</h1>
          <p>Dit recept bestaat niet meer of is verwijderd.</p>
          <Link to="/" className="back-home-button">
            Ga Terug naar Home
          </Link>
        </div>
      </div>
    );
  }

  if (!recipe) return <p>Loading...</p>;

  return (
    <div className="page">
      <div className="recipe-detail-container">
        <h1 className="recipe-title">{recipe.title}</h1>

        <div className="toggle-container">
          <label className="switch">
            <input
              type="checkbox"
              checked={isCookModeOn}
              onChange={() => setIsCookModeOn(!isCookModeOn)}
            />
            <span className="slider"></span>
          </label>
          <span id="toggle-label">
            {isCookModeOn ? "Kookstand Aan" : "Kookstand Uit"}
          </span>
        </div>

        <div className="recipe-content-layout">
          <div className="recipe-left-column">
            <div className="recipe-image-container">
              {recipe.photo ? (
                <img
                  src={recipe.photo}
                  alt={recipe.title}
                  className="recipe-image"
                />
              ) : (
                <div className="placeholder-image">
                  <p>Geen afbeelding beschikbaar</p>
                </div>
              )}
            </div>

            <div className="recipe-ingredients">
              <h2>Ingrediënten</h2>
              {recipe.ingredients && recipe.ingredients.length > 0 ? (
                <ul className="ingredient-list">
                  {recipe.ingredients.map((ingredient, index) => (
                    <li key={index} className="ingredient-item">
                      {ingredient}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Geen ingrediënten beschikbaar.</p>
              )}
            </div>
          </div>

          <div className="recipe-instructions">
            <span
              className="recipe-author"
              onClick={handleAuthorClick}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Toegevoegd door: {recipe.author}
            </span>
            <h2>Instructies</h2>
            {recipe.instructions ? (
              <div
                className="instruction-content"
                dangerouslySetInnerHTML={{ __html: recipe.instructions }}
              ></div>
            ) : (
              <p>Geen instructies beschikbaar.</p>
            )}
          </div>
        </div>
        {user && user.username === recipe.author && (
          <div className="recipe-actions">
            <button
              onClick={() => navigate(`/edit/${id}`)}
              className="action-button"
            >
              Bewerken
            </button>
            <button onClick={handleDelete} className="action-button">
              Verwijderen
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecipeDetails;
